<template>
  <div style="margin-top: 20px;margin-right: 20px">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="位置id" prop="positionId">
        <el-input type="text" :disabled="this.isTrue" v-model="ruleForm.positionId" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="楼宇" prop="building">
        <el-input type="text" v-model="ruleForm.building" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="楼层" prop="floor">
        <el-input v-model="ruleForm.floor"></el-input>
      </el-form-item>
      <el-form-item label="位置" prop="position">
        <el-input v-model="ruleForm.position"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
  computed: {
    row() {
      return this.$route.query.row
    }
  },
  data() {
    var checkfloor = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('楼层不能为空'));
      }else
        callback();
    };
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('位置不能为空'));
      }else
        callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入位置id'));
      } else {
        if (this.ruleForm.building !== '') {
          this.$refs.ruleForm.validateField('building');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入楼宇'));
      } else {
        callback();
      }
    };
    return {
      isTrue:false,
      ruleForm: {
        positionId: '',
        building: '',
        floor: '',
        position:''
      },
      rules: {
        positionId: [
          { validator: validatePass, trigger: 'blur' }
        ],
        building: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        floor: [
          { validator: checkfloor, trigger: 'blur' }
        ] ,
        position: [
          { validator: checkAge, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (typeof (this.row) != 'undefined') {//更新
            this.$axios.post("/sys-api/site-api/updatePoition", {id:this.ruleForm.positionId,departmentId:this.$route.query.id,building:this.ruleForm.building,floor:this.ruleForm.floor,place:this.ruleForm.position}, (res) => {
              if (res.code === '100') {
                this.$message.success("更新成功");
                this.$router.push({name: 'UnitDetail',query:{id:this.$route.query.id}})
              }
            })
          }else {//新增
            this.$axios.post("/sys-api/site-api/addPoition", {id:this.ruleForm.positionId,departmentId:this.$route.query.id,building:this.ruleForm.building,floor:this.ruleForm.floor,place:this.ruleForm.position}, (res) => {
              if (res.code === '100') {
                this.$message.success("保存成功");
                this.$router.push({name: 'UnitDetail',query:{id:this.$route.query.id}})
              }
            })
          }
        } else {
          // this.$message.error()
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof (this.row) != 'undefined') {
        this.isTrue = true;
        this.ruleForm.positionId = this.row.id;
        this.ruleForm.position = this.row.place;
        this.ruleForm.building = this.row.building;
        this.ruleForm.floor = this.row.floor;
      }
    })
  }
}
</script>

<style scoped>
</style>
